import { FunctionComponent, useEffect, useState } from "react";
import { getRankingsData } from "../api/Api";
import { DomainRank } from "../api/types";
import { RankingIcon } from "./RankingIcon";
import "./ranking_table.css";

export interface Props {}

interface parsedRanking {
  domain: string;
  rank: number;
  rankChange: number;
  category: string;
}

function parseRanking(raw: DomainRank): parsedRanking {
  return {
    domain: raw.domain,
    rank: parseInt(raw.rank),
    rankChange: parseInt(raw.rankChange),
    category: raw.category,
  };
}

function faviconSrcForDomain(domain: string): string {
  return `https://www.google.com/s2/favicons?sz=64&domain_url=${domain}`;
}

export const RankingsTable: FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [rawRankings, setRawRankings] = useState<DomainRank[]>();

  useEffect(() => {
    setLoading(true);

    getRankingsData()
      .then((res) => {
        setRawRankings(res.rankingEntries);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  } else if (!rawRankings) {
    return (
      <div className="loading-container">
        <p>No Data</p>
      </div>
    );
  }

  let parsedRankings = rawRankings.map(parseRanking);

  // Sort our rankings to ensure we have proper order
  parsedRankings.sort((a, b) => {
    return a.rank - b.rank;
  });

  return (
    <div id="table-container">
      <h1>Domain Popularity Rankings</h1>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Change</th>
            <th>Domain</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {parsedRankings.map((ranking) => (
            <tr>
              <td>{ranking.rank + 1}</td>
              <td>
                <RankingIcon rankChange={ranking.rankChange} />
              </td>
              <td>
                <div className="domain-name-container">
                  <img src={faviconSrcForDomain(ranking.domain)} alt={`Favicon for ${ranking.domain}`} />
                  <p>{ranking.domain}</p>
                </div>
              </td>
              <td>{ranking.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
