import { FunctionComponent } from "react";
import { CaretUpFill, CaretDownFill, DashCircleFill } from "react-bootstrap-icons";
import "./ranking_icons.css";

export interface Props {
  rankChange: number;
}

export const RankingIcon: FunctionComponent<Props> = ({ rankChange }) => {
  if (rankChange === 0) {
    return (
      <div className="ranking-change-container">
        <DashCircleFill color="gray" />
        <p>{rankChange}</p>
      </div>
    );
  } else if (rankChange > 0) {
    return (
      <div className="ranking-change-container">
        <CaretUpFill color="green" />
        <p>{rankChange}</p>
      </div>
    );
  } else {
    return (
      <div className="ranking-change-container">
        <CaretDownFill color="red" />
        <p>{rankChange}</p>
      </div>
    );
  }
};
