import { AttacksResponse, RankingsResponse, TrafficResponse } from "./types";

const API_ENDPOINT = "https://assessment.dnsge.workers.dev";

function endpoint(path: string): string {
  return API_ENDPOINT + path;
}

export async function getTrafficData(): Promise<TrafficResponse> {
  return fetch(endpoint("/traffic-change")).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      return Promise.reject((data && data.message) || res.statusText);
    }

    return Promise.resolve(data as TrafficResponse);
  });
}

export async function getRankingsData(): Promise<RankingsResponse> {
  return fetch(endpoint("/popular-domains")).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      return Promise.reject((data && data.message) || res.statusText);
    }

    return Promise.resolve(data as RankingsResponse);
  });
}

export async function getAttacksData(): Promise<AttacksResponse> {
  return fetch(endpoint("/attack-layer3")).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      return Promise.reject((data && data.message) || res.statusText);
    }

    return Promise.resolve(data as AttacksResponse);
  });
}

export {};
