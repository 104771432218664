import React from "react";
import "./App.css";
import { TrafficGraph } from "./components/TrafficGraph";
import { AttacksGraph } from "./components/AttacksGraph";
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from "react-router-dom";
import {
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { RankingsTable } from "./components/RankingsTable";

const isActivePred = (navData: { isActive: boolean; isPending: boolean }) =>
  navData.isActive ? "nav-active" : "";

function App() {
  ChartJS.register(LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

  return (
    <Router>
      <div id="container">
        <nav id="navbar">
          <p id="navbar-header">Cloudflare Statistics Dashboard</p>
          <p id="idiot-guide">Click on the links below to see the different available data</p>
          <ul>
            <li>
              <NavLink to="/traffic" className={isActivePred}>
                Traffic
              </NavLink>
            </li>
            <li>
              <NavLink to="/rankings" className={isActivePred}>
                Domain Rankings
              </NavLink>
            </li>
            <li>
              <NavLink to="/attacks" className={isActivePred}>
                Layer 3 Attacks
              </NavLink>
            </li>
          </ul>
        </nav>
        <div id="page-content">
          <Routes>
            <Route path="/traffic" element={<TrafficGraph />} />
            <Route path="/rankings" element={<RankingsTable />} />
            <Route path="/attacks" element={<AttacksGraph />} />
            <Route path="/" element={<Navigate to="/traffic" />} />
            <Route path="*" element={<h1>Not Found</h1>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
