export interface TrafficResponse {
  data: {
    total: TimestampDataPoint[];
    http: TimestampDataPoint[];
  };
  meta: TimeMeta;
}

export interface TimestampDataPoint {
  timestamp: string;
  value: string;
}

export interface AttacksResponse {
  data: TimestampDataPoint[];
  meta: TimeMeta;
}

export interface TimeMeta {
  dateRange: {
    startTime: string;
    endTime: string;
  };
  aggInterval: string;
  lastUpdated: string;
}

export interface DatePoint {
  x: string;
  y: number;
}

export function parseDatePoints(points: TimestampDataPoint[]): DatePoint[] {
  return points.map((dataPoint) => ({
    x: dataPoint.timestamp,
    y: parseFloat(dataPoint.value),
  }));
}

export interface RankingsResponse {
  rankingEntries: DomainRank[];
}

export interface DomainRank {
  rank: string;
  rankChange: string;
  domain: string;
  category: string;
}
