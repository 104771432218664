import { ChartOptions, ChartData } from "chart.js";
import { FunctionComponent, useEffect, useState } from "react";
import { getTrafficData } from "../api/Api";
import { DatePoint, parseDatePoints, TimeMeta, TimestampDataPoint } from "../api/types";
import { Line } from "react-chartjs-2";
import { CLOUDFLARE_GRAY, CLOUDFLARE_ORANGE } from "../constants";
import "chartjs-adapter-date-fns";

export interface Props {}

export const TrafficGraph: FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [trafficHttpData, setTrafficHttpData] = useState<TimestampDataPoint[]>();
  const [trafficTotalData, setTrafficTotalData] = useState<TimestampDataPoint[]>();
  const [metaData, setMetaData] = useState<TimeMeta>();

  useEffect(() => {
    setLoading(true);

    getTrafficData()
      .then((res) => {
        setTrafficHttpData(res.data.http);
        setTrafficTotalData(res.data.total);
        setMetaData(res.meta);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  } else if (!trafficHttpData || !trafficTotalData || !metaData) {
    return (
      <div className="loading-container">
        <p>No Data</p>
      </div>
    );
  }

  const options: ChartOptions<"line"> = {
    plugins: {
      title: {
        display: true,
        text: "Cloudflare Internet Traffic Over Time",
        font: {
          size: 30,
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        title: {
          display: true,
          text: "Time",
          font: {
            size: 16,
          },
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        max: 1.0,
        title: {
          display: true,
          text: "Load",
          font: {
            size: 16,
          },
        },
      },
    },
  };

  const data: ChartData<"line", DatePoint[]> = {
    datasets: [
      {
        label: "HTTP Traffic",
        data: parseDatePoints(trafficHttpData),
        borderColor: CLOUDFLARE_GRAY,
      },
      {
        label: "Total Traffic",
        data: parseDatePoints(trafficTotalData),
        borderColor: CLOUDFLARE_ORANGE,
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={data} />
      <div className="last-updated">Last updated at {new Date(metaData.lastUpdated).toLocaleString()}</div>
    </div>
  );
};
